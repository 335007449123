const theme = {
	type: 'dark',
	scrollbar: {
		style: 'none', // oneOf['none', 'default', 'primary']
	},
	navigation: {
		number: 1,
		height: '3.5em',
		tablet: {
			number: 1,
			height: '3.9em',
		},
		desktop: {
			number: 1,
			height: '3.9em'
		}
	},
	typography: {
		fontSize: 20,
		body1: { fontSize: 20 },
		h1: { 'text-transform': 'capitalize' },
		h2: { 'text-transform': 'capitalize' },
		h3: { 'text-transform': 'capitalize' },
		h4: { 'text-transform': 'capitalize' },
		h5: { 'text-transform': 'capitalize' },
		h6: { 'text-transform': 'capitalize' },
		desktop: {
			body1: { fontSize: 22 },
			h1: { 'text-transform': 'capitalize' },
			h2: { 'text-transform': 'capitalize' },
			h3: { 'text-transform': 'capitalize' },
			h4: { 'text-transform': 'capitalize' },
			h5: { 'text-transform': 'capitalize' },
			h6: { 'text-transform': 'capitalize' },
		}
	},
	palette: {
		primary: {
			lightest: "#c9c9c9",
			light: '#E43027',
			main: '#BF0A0A',
			contrastText: '#FFFFFF'
		},
		secondary: {
			light: '#757575',
			main: '#000000',
			border: '#757575',
			contrastText: '#FFFFFF'
		},
		border:{
			primary: '#373A3C',
			secondary: '#2d2d2d',
			contrast: '#FFFFFF'
		},
		background: {
			default: '#070707',
			primary: {
				main: '#000000'
			}
		},
		text:{
			primary: '#FFFFFF',
			secondary: '#E4E4E4',
			active: '#E43027',
			link: '#E43027',
		},
		site: {
			background: '#000000',
			activeText: '#E43027',
			mobile: {
				contrastText: '#E4E4E4',
				activeText: '#E43027'
			}
		},
		button: {
			contained: {
				primary: {
					background: '#BF0A0A',
					borderColor: '#BF0A0A'
				}
			},
			outline: {
				quintary: {
					borderColor: '#373A3C',
					hover: {
						borderColor: '#BF0A0A',
						textDecoration: 'none',
					},
					focus: {
						borderColor: '#BF0A0A',
						textDecoration: 'none',
					}
				}
			},
			text: {
				primary: {
					hover: {
						color: '#E43027'
					}
				}
			}
		}
	}
}

module.exports = theme
