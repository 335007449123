import times from 'lodash/times'
import keys from 'tcweb-material-components/core/poly/keys'

/*
    Base for page elements
    [WARNING]: Try not to add more code here unless absolutely necessary
*/


const getLineClampClasses = (breakpoints) => keys(breakpoints.values).reduce((styles, key) => {
    return {
        ...styles,
        ...times(4).reduce((acc, i) => {
            return {
                ...acc,
                [`.line-clamp-${i}-${key}`]: {
                    [breakpoints.up(key)]: {
                        overflow: 'hidden',
                        display: '-webkit-box',
                        WebkitLineClamp: `${i}`,
                        WebkitBoxOrient: 'vertical !important'
                    }
                }
            }
        }, {})
    }
}, {})

export default ({ palette, breakpoints, navigation }) => ({
    'body': {
        margin: 0,
        color: palette.text.primary,
        backgroundColor: palette.background.default
    },

    // Push footer to bottom of page - for small pages
    'main': {
        flexDirection: 'column',
        display: 'flex',
        flex: '1 1 auto'
    },

    // For anchors part of a page / post (not swapped)
    'a': {
        color: palette.text.link,
        textDecoration: 'none',
        '&:hover, &:focus': {
            textDecoration: 'underline'
        }
    },

    // So that screen readers don't read the contents of noscript
    '.gatsby-image-wrapper': { 'noscript': {  display: 'none' } },

    // Remove arrows on some input fields
    'input[type="number"].no-spinner::-webkit-inner-spin-button, \
     input[type="number"].no-spinner::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: 0
    },

    // Scroll past nav height when clicking link with # destination
    '[id]': {
        scrollMarginTop: `calc(${navigation.height})`,
        [breakpoints.down('md')]: { scrollMarginTop: `calc(${navigation.tablet.height})` },
        [breakpoints.up('md')]: { scrollMarginTop: `calc(${navigation.desktop.height})` }
    },

    // Unordered lists numbering of sub items
    'ol': {
        counterReset: 'item',
        '& > li': {
            display: 'block',
            '&:before': {
                content: 'counters(item, ".") ". "',
                counterIncrement: 'item'
            }
        }
    },

    '.smooth-scroll': {
        scrollBehavior: 'smooth'
    },
    '::-ms-reveal': {
		display: 'none'
	},
    ...getLineClampClasses(breakpoints)

});