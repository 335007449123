import keys from 'tcweb-material-components/core/poly/keys'
import find from 'tcweb-material-components/core/poly/find'
import get from 'tcweb-material-components/core/poly/get'
import { useStaticQuery, graphql } from 'gatsby'
/*
    Base for page elements
    [WARNING]: Try not to add more code here unless absolutely necessary
*/

const DEFAULT_SCRATCH_HEIGHT = '3em'

const getUrl = (backgrounds, assetName, bgGroup) =>
    get(
        find((bg) => bg.name === assetName, get(backgrounds, `${bgGroup}.nodes`, '')),
        'publicURL',
        ''
    )

const getDefaultScratch = (backgrounds, breakpoints, highContrast) =>
    keys(breakpoints.values).reduce((styles, breakpoint) => {
        const backgroundUrl = getUrl(backgrounds, `bg-default-scratch`, 'default')

        return {
            ...styles,
            [breakpoints.only(breakpoint)]: {
                [highContrast.mediaQuery('none')]: {
                    background: `url('${backgroundUrl}')`,
                    backgroundRepeat: 'repeat-x',
                    // backgroundSize: 'cover',
                    // backgroundPositionY: 'bottom'
                    height: '80px'
                },
                top: `calc(3px - ${DEFAULT_SCRATCH_HEIGHT})`,
                height: DEFAULT_SCRATCH_HEIGHT
            }
        }
    })

const getAspectRatio = (breakpoint) => {
    switch (breakpoint) {
        case 'xl':
            return 16 / 9
        case 'lg':
            return 5 / 4
        case 'md':
            return 479 / 512
        case 'sm':
            return 5 / 8
        default:
            return 25 / 48
    }
}

const getDefaultBackground = (backgrounds, breakpoints, palette, highContrast) =>
    keys(breakpoints.values).reduce((styles, breakpoint) => {
        // const aspectRatio = getAspectRatio(breakpoint)
        const backgroundUrl = getUrl(backgrounds, `bg-default`, 'default')

        return {
            ...styles,
            [breakpoints.only(breakpoint)]: {
                [highContrast.mediaQuery('none')]: {
                    background: `url('${backgroundUrl}') repeat top left`,
                },
            }
        }
    }, {})

export default ({ palette, breakpoints, accessibility: { highContrast } }) => {
    const backgrounds = useStaticQuery(graphql`
        {
            darkIce: allFile(filter: { name: { regex: "/bg-dark-ice/" } }) {
                nodes {
                    publicURL
                    name
                }
            }
            lightIce: allFile(filter: { name: { regex: "/bg-ice-/" } }) {
                nodes {
                    publicURL
                    name
                }
            }
            notifications: allFile(filter: { name: { regex: "/bg-notification/" } }) {
                nodes {
                    publicURL
                    name
                }
            }
            mountains: allFile(filter: { name: { regex: "/bg-mountains/" } }) {
                nodes {
                    publicURL
                    name
                }
            }
            juvie: allFile(filter: { name: { regex: "/bg-juvie/" } }) {
                nodes {
                    publicURL
                    name
                }
            }
            default: allFile(filter: { name: { regex: "/default/" } }) {
                nodes {
                    publicURL
                    name
                }
            }
        }
    `)

    return {
        '.bg-default': {
            position: 'relative',
            ...getDefaultBackground(backgrounds, breakpoints, palette, highContrast),
            '&.top-overlay': {
                display: 'inline-block', // Disable margin collapsing
                zIndex: 1,
                '&:before': {
                    display: 'block',
                    position: 'absolute',
                    content: "''",
                    width: '100%',
                    left: 0,
                    right: 0,

                    ...getDefaultScratch(backgrounds, breakpoints, highContrast),

                    [breakpoints.down('sm')]: {
                        top: 'calc(3px - 80px)'
                    }
                }
            }
        },

        '&:after': {
            position: 'absolute',
            content: "''",
            zIndex: -2,
            top: 0,
            left: 0,
            width: '100%',
            maxHeight: `100%`,

            ...getDefaultBackground(backgrounds, breakpoints, palette, highContrast)
        },

        '.bg-primary': {
            position: 'relative',
            ...(breakpoints) =>
                ['xs', 'md', 'lg'].reduce((styles, breakpoint) => {
                    const backgroundUrl = getUrl(backgrounds, `bg-dark-ice-${breakpoint}`, 'darkIce')
                    return {
                        ...styles,
                        [breakpoints.up(breakpoint)]: {
                            [highContrast.mediaQuery('none')]: {
                                background: `url('${backgroundUrl}') no-repeat top left / cover`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover'
                            }
                        }
                    }
                })
        },

        '.bg-secondary': {
            position: 'relative',
            [highContrast.mediaQuery('none')]: {
                [breakpoints.down('sm')]: {
                    background: `url('${getUrl(backgrounds, 'bg-secondary-xs', 'secondary')}') no-repeat top right / cover`
                },
                [breakpoints.up('md')]: {
                    background: `url('${getUrl(backgrounds, 'bg-secondary-md', 'secondary')}') no-repeat top left / cover`
                }
            }
        },

        '.bg-juvie': {
            position: 'relative',
            [highContrast.mediaQuery('none')]: {
                [breakpoints.down('sm')]: {
                    background: `url('${getUrl(backgrounds, 'bg-juvie-xs', 'juvie')}') no-repeat top right / cover`
                },
                [breakpoints.up('md')]: {
                    background: `url('${getUrl(backgrounds, 'bg-juvie-md', 'juvie')}') no-repeat top left / cover`
                }
            }
        },

        '.bg-tc-saw-left': {
            [highContrast.mediaQuery('none')]: {
                [breakpoints.up('sm')]: {
                    '.wp-block-media-text__media': {
                        background: 'radial-gradient(ellipse at center, #3768ad54 29%, transparent 70%)'
                    },
                    [breakpoints.up('md')]: {
                        background:
                            'url(https://cdn.gearsofwar.com/gearsofwar/sites/9/2020/02/the-coalition-blade-5e5835052a4bb.svg) no-repeat top left, radial-gradient(#3768ad54 40%, transparent 70%);'
                    }
                }
            }
        },

        '.bg-tc-saw-right': {
            [highContrast.mediaQuery('none')]: {
                [breakpoints.up('sm')]: {
                    '.wp-block-media-text__media': {
                        background: 'radial-gradient(ellipse at center, #3768ad54 29%, transparent 70%)'
                    }
                }
            },
            [breakpoints.up('md')]: {
                background:
                    'url(https://cdn.gearsofwar.com/gearsofwar/sites/9/2020/02/the-coalition-blade-5e5835052a4bb.svg) no-repeat top right, radial-gradient(#3768ad54 40%, transparent 70%)'
            }
        },

        '.bg-notification': {
            position: 'relative',
            [breakpoints.down('sm')]: {
                [highContrast.mediaQuery('none')]: {
                    background: `url('${getUrl(backgrounds, 'bg-notification-sm', 'notifications')}') no-repeat top / cover`
                }
            },
            [breakpoints.up('md')]: {
                [highContrast.mediaQuery('none')]: {
                    background: `url('${getUrl(backgrounds, 'bg-notification', 'notifications')}') no-repeat top / cover`
                }
            },
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
        },

        '.bg-notification-cta': {
            position: 'relative',
            [breakpoints.down('sm')]: {
                [highContrast.mediaQuery('none')]: {
                    background: `url('${getUrl(backgrounds, 'bg-notification-cta-sm', 'notifications')}') no-repeat top / cover`
                }
            },
            [breakpoints.up('md')]: {
                [highContrast.mediaQuery('none')]: {
                    background: `url('${getUrl(backgrounds, 'bg-notification-cta', 'notifications')}') no-repeat top / cover`
                }
            },
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
        },

        '.bg-mountains': {
            position: 'relative',
            [breakpoints.down('sm')]: {
                [highContrast.mediaQuery('none')]: {
                    background: `url('${getUrl(backgrounds, 'bg-mountains-sm', 'mountains')}') no-repeat left top / cover`
                }
            },
            [breakpoints.up('md')]: {
                [highContrast.mediaQuery('none')]: {
                    background: `url('${getUrl(backgrounds, 'bg-mountains', 'mountains')}') no-repeat left top / cover`
                }
            },
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
        }
    }
}
