import bahnschriftFont from '../../assets/fonts/bahnschrift.ttf'
import { makeStyles } from '@material-ui/core/styles';

/*
    Typography classes
    [WARNING]: Try not to add more code here unless absolutely necessary
*/

export default ({ typography, breakpoints }) => ({
    ...makeStyles({
        '@font-face' : {
            fontFamily: 'bahnschrift',
            fontDisplay: 'swap',
            src: `url(${bahnschriftFont}) format('truetype')`,
            fontWeight: '300 1000',
            fontStretch: '75% 100%'
        },
    })(),
    // Target Safari 10.1+
    // @mixin target-Safari {
    //     @media not all and (min-resolution: 0.001dpcm) {
    //         @supports (-webkit-appearance: none) {
    //             @content;
    //         }
    //     }
    // }
    // @include target-Safari {
    //     @font-face {
    //         font-family: 'bahnschrift';
    //         font-display: swap;
    //         src: url('../assets/fonts/bahnschrift.ttf') format('truetype');
    //         font-weight: normal;
    //         font-stretch: 75% 100%;
    //     }
    // }
    'body': { ...typography.body1, [breakpoints.up('md')]:{ ...typography.desktop.body1 } },
    '.h1, h1': { ...typography.h1, [breakpoints.up('md')]:{ ...typography.desktop.h1 } },
    '.h2, h2': { ...typography.h2, [breakpoints.up('md')]:{ ...typography.desktop.h2 } },
    '.h3, h3': { ...typography.h3, [breakpoints.up('md')]:{ ...typography.desktop.h3 } },
    '.h4, h4': { ...typography.h4, [breakpoints.up('md')]:{ ...typography.desktop.h4 } },
    '.h5, h5': { ...typography.h5, [breakpoints.up('md')]:{ ...typography.desktop.h5 } },
    '.h6, h6': { ...typography.h6, [breakpoints.up('md')]:{ ...typography.desktop.h6 } }
})